import moment from 'moment'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'
import Invoice from './Invoice'
import WebMessage from './WebMessage'
import Model from './interface/Model'
import Api from './Api'

export default class Commission extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'commissions' as string | null,
      plural: 'commissions' as string | null,
    },
  }

  public id: null | string = null

  public user_id: null | string = ''

  public period: null | string = ''

  public invoice_id: null | string = ''

  public type: null | string = 'manual'

  public media_plan_ids: any = []

  public media_plan_item_ids: any = []

  public commission_rule_ids: any = []

  public notes: null | string = ''

  public status: null = null

  public media_plans: any = []

  public user: any = {}

  public invoice!: Invoice

  public base_amount: number = 0

  public base_commission: number = 0

  public eligible_amount: number = 0

  public eligible_commission: number = 0

  public confirmed_commission: number = 0

  public final_commission: number = 0

  public payable_commission: number = 0

  public paid_commission: number = 0

  public metadata: any = {}

  public get quarter(): string {
    let date = moment(this.period)

    return `Q${date.quarter()}-${date.year()}`
  }

  public get invoice_gross_amount(): number {
    return this.invoice.gross_subtotal
  }

  public get invoice_net_amount(): number {
    return this.invoice.total
  }

  public get costs(): number {
    return this.invoice.cost_total + this.invoice.expense_total
  }

  public get cost_breakdown(): any {
    return this.invoice.metadata.costs
  }

  public get invoice_paid_amount(): number {
    return this.invoice.paid
  }

  public get invoice_paid_date(): string {
    return this.invoice.paid_at ?? '-'
  }

  public get due_commission(): number {
    return this.payable_commission - this.paid_commission
  }

  public get apiData(): any {
    return {
      id: this.id,
      user_id: this.user_id,
      period: this.period,
      invoice_id: this.invoice_id,
      type: this.type,
      media_plan_ids: this.media_plan_ids,
      media_plan_item_ids: this.media_plan_item_ids,
      commission_rule_ids: this.commission_rule_ids,
      notes: this.notes,
    }
  }

  public onSave(response: any) {
    WebMessage.success('Commission saved')
  }

  public static recalculate(
    target_start: string,
    target_end: string,
    payment_cutout: string,
    resync_cost: boolean,
  ) {
    const api = new Api()
    const instance_id = getModule(SystemtModule)._uuid
    return api.post('commissions/recalculate', {
      target_start,
      target_end,
      payment_cutout,
      resync_cost,
      instance_id,
    })
  }

  public static download(
    filter_type: string,
    target_start: string,
    target_end: string,
    payment_cutout: string,
  ) {
    const api = new Api()
    const instance_id = getModule(SystemtModule)._uuid
    return api.post('commissions/download', {
      filter_type,
      target_start,
      target_end,
      payment_cutout,
      instance_id,
    })
  }

  public static getCommissionPerUser(
    filter_type: string,
    target_start: string,
    target_end: string,
    expense_id: string,
  ) {
    const api = new Api()
    const instance_id = getModule(SystemtModule)._uuid
    return api.get('commissions/per-user', {
      filter_type,
      target_start,
      target_end,
      expense_id,
      instance_id,
    })
  }

  public static updatePayments(
    action: string,
    filter_type: string,
    target_start: string,
    target_end: string,
    expense_id: string,
    distribution: any,
  ) {
    const api = new Api()
    const instance_id = getModule(SystemtModule)._uuid
    return api.post('commissions/apply-payment', {
      action,
      filter_type,
      target_start,
      target_end,
      expense_id,
      distribution,
      instance_id,
    })
  }

  public static applyPayment(
    filter_type: string,
    target_start: string,
    target_end: string,
    expense_id: string,
    distribution: any,
  ) {
    return Commission.updatePayments(
      'apply',
      filter_type,
      target_start,
      target_end,
      expense_id,
      distribution,
    )
  }

  public static removePayment(
    filter_type: string,
    target_start: string,
    target_end: string,
    expense_id: string,
    distribution: any,
  ) {
    return Commission.updatePayments(
      'break',
      filter_type,
      target_start,
      target_end,
      expense_id,
      distribution,
    )
  }
}
