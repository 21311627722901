
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import FormInput from '@/components/FormInput/FormInput.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import ViewModel from '@/models/ViewModel'
import DataTable from '@/components/DataTable/index.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import Commission from '@/models/Commission'
import moment from 'moment'
import WebMessage from '@/models/WebMessage'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import ExpensePicker from '@/components/ExpensePicker/ExpensePicker.vue'
import Expense from '@/models/Expense'
import { currencyMask } from '@/models/interface/Masks'
import SelectOption from '@/models/interface/SelectOption'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import FaqModal from '@/components/FaqModal/FaqModal.vue'
import CommissionHomeTable from './commision-home-table'
import CommissionStatusBadge from './Components/CommissionStatusBadge.vue'

@Component({
  components: {
    Widget,
    FormWizard,
    TabContent,
    FormInput,
    DataTable,
    SearchInput,
    IconAction,
    CommissionStatusBadge,
    DatePicker,
    CheckboxInput,
    ExpensePicker,
    SelectPicker,
    FaqModal,
  },
})
export default class CommissionHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public fields: any = []

  public loading: boolean = false

  public busy = true

  public query: string[] = []

  public fieldFilters: any = {}

  public ready: boolean = false

  public records: number = 0

  public selected: string[] = []

  public commissions: any = []

  public target_expense: null | string = null

  public target_expense_model: Expense | null = null

  public modal_step: number = 1

  public target_start = moment().subtract(1, 'months').format('YYYY-MM-DD')

  public target_end = moment().subtract(1, 'months').format('YYYY-MM-DD')

  public payment_cutout_date = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')

  public resync_cost = false

  public filter_type: string = 'payment_date'

  public payment_action: string = 'apply'

  public filter_options: SelectOption[] = [
    new SelectOption('Payment Date', 'payment_date'),
    new SelectOption('Invoice Date', 'invoice_date'),
  ]

  public commissions_per_user: any = []

  public invalid_expense = false

  public get masks() {
    return {
      currencyMask,
    }
  }

  public help =
    ' - <strong>Elegible Commission</strong>: Base amount of commission after cost used in the calculation.<br />'
    + '- <strong>Confirmed Commission</strong>: Final commission calculated after threshold, escalators and caps<br />'
    + '- <strong>Payable Commission</strong>: Payable commission based on the confirmed commission & Invoice Paid amount.<br />'

  public query_settings: any = {
    user_fields: [
      {
        name: 'user',
        key: 'user_id',
      },
    ],
    invoice_fields: [
      {
        name: 'invoice',
        key: 'invoice_id',
      },
    ],
    custom_fields: [
      {
        name: 'status:pending',
        value: 'status:pending',
      },
      {
        name: 'status:paid',
        value: 'status:paid',
      },
      {
        name: 'status:threshold_not_met',
        value: 'status:threshold_not_met',
      },
      {
        name: 'status:cap_reached',
        value: 'status:cap_reached',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get total_commissions() {
    let total = {
      payable_commission: 0,
      paid_commission: 0,
      due_commission: 0,
      applied: 0,
      remaining: 0,
    }
    if (!this.commissions_per_user) return total

    this.commissions_per_user.forEach((row: any) => {
      if (row.payable_commission) total.payable_commission += parseFloat(row.payable_commission)
      if (row.paid_commission) total.paid_commission += parseFloat(row.paid_commission)
      if (row.amount) total.applied += parseFloat(row.amount)

      total.due_commission = total.payable_commission - total.paid_commission
      total.remaining = total.due_commission - total.applied
    })

    return total
  }

  public get paymentModalTitle() {
    if (this.payment_action === 'break') {
      return 'Unlink Expense'
    }
    if (this.modal_step === 2 && this.target_expense_model) {
      return this.target_expense_model.name
    }

    return 'Add Payment'
  }

  public mounted() {
    this.loadFilters()
    this.fields = CommissionHomeTable
    this.loading = false
  }

  public async rows(ctx: any): Promise<any> {
    this.loading = true
    this.busy = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    return Commission.paginate({
      page_size: ctx.perPage,
      page: ctx.currentPage,
      order_by: ctx.sortBy,
      order: ctx.sortDesc ? 'desc' : 'asc',
      query: [...ctx.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.busy = false
      this.loading = false
      this.commissions = result.data
      return this.commissions
    })
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }
  }

  public validateStep() {
    return true
  }

  public async onSubmit() {
    return true
  }

  // Refresh dataTable
  public refresh() {
    this.dataTable.refresh()
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.refresh()
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'commission',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('commission').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'commission',
      data: null,
    })
  }

  public recalculateCommissions() {
    // Set dates for the previous quarter
    let current_q = moment().startOf('quarter').subtract(1, 'month').startOf('quarter')

    this.target_start = current_q.format('YYYY-MM-DD')
    this.target_end = current_q.endOf('quarter').format('YYYY-MM-DD')
    this.resync_cost = false
    this.$bvModal.show('modal-recalculate')
  }

  public confirmRecalculate() {
    const start = moment(`${this.target_start}-01`)
    const end = moment(`${this.target_end}-01`)

    if (start.quarter() !== end.quarter() || start.year() !== end.year()) {
      WebMessage.error(
        'You can only recalculate commissions within a single quarter, please review the dates.',
      )
      return
    }

    this.$bvModal.hide('modal-recalculate')

    Commission.recalculate(
      `${this.target_start}-01`,
      `${this.target_end}-01`,
      `${this.payment_cutout_date}-01`,
      this.resync_cost,
    ).then(() => {
      WebMessage.success(
        'Recalculating Commissions, this may take a while. We will let you know when it is done.',
      )
    })
  }

  public exportCommissions() {
    // Set dates for the previous quarter
    let current_q = moment().startOf('quarter').subtract(1, 'month').startOf('quarter')

    this.target_start = current_q.format('YYYY-MM-DD')
    this.target_end = current_q.endOf('quarter').format('YYYY-MM-DD')

    this.$bvModal.show('modal-export')
  }

  public confirmExport() {
    this.$bvModal.hide('modal-export')
    WebMessage.success(
      'Exporting Commissions, this may take a while. We will let you know when it is done.',
    )
    Commission.download(
      this.filter_type,
      `${this.target_start}-01`,
      `${this.target_end}-01`,
      `${this.payment_cutout_date}-01`,
    )
  }

  public addPayment() {
    // Set dates for the previous quarter
    let current_q = moment().startOf('quarter').subtract(1, 'month').startOf('quarter')

    this.target_expense = null
    this.target_expense_model = null
    this.modal_step = 1

    this.target_start = current_q.format('YYYY-MM-DD')
    this.target_end = current_q.endOf('quarter').format('YYYY-MM-DD')
    this.payment_action = 'apply'
    this.$bvModal.show('modal-add-payment')
  }

  public unlinkPayment() {
    // Set dates for the previous quarter
    let current_q = moment().startOf('quarter').subtract(1, 'month').startOf('quarter')

    this.target_expense = null
    this.target_expense_model = null
    this.modal_step = 1

    this.target_start = current_q.format('YYYY-MM')
    this.target_end = current_q.endOf('quarter').format('YYYY-MM')
    this.payment_action = 'break'
    this.$bvModal.show('modal-add-payment')
  }

  public confirmUnlinkPayment() {
    if (!this.target_expense_model) {
      WebMessage.error('Please select an expense')
      return
    }
    WebMessage.doubleConfirm(
      `This action will unlink the expense "${this.target_expense_model.name}" from the commission and update the amount paid for each connected invoice. Are you sure you want to continue?`,
      'Unlink Expense',
      'Yes, Unlink Expense',
      { okTitle: 'Unlink' },
    ).then(result => {
      if (result) {
        if (!this.target_expense) {
          WebMessage.error('Please select an expense')
          return
        }
        this.$bvModal.hide('modal-add-payment')
        Commission.removePayment(
          this.filter_type,
          `${this.target_start}-01`,
          `${this.target_end}-01`,
          this.target_expense,
          this.commissions_per_user,
        ).then(() => {
          WebMessage.success('Updating Payments, we will let you know once the update is complete.')
        })
      }
    })
  }

  @Watch('target_expense')
  public onSelectExpense() {
    this.invalid_expense = false
    if (this.target_expense) {
      Expense.find(this.target_expense).then((expense: Expense) => {
        this.target_expense_model = expense
      })
    } else {
      this.target_expense_model = null
    }
  }

  public fetchCommissionsForPayment() {
    this.busy = true
    this.modal_step = 2
    this.invalid_expense = false
    if (!this.target_expense_model || !this.target_expense) return

    Commission.getCommissionPerUser(
      this.filter_type,
      `${this.target_start}-01`,
      `${this.target_end}-01`,
      this.target_expense,
    )
      .then((result: any) => {
        const total = result.data.result.reduce(
          (acc: number, c: any) => acc + c.payable_commission - c.paid_commission,
          0,
        )
        this.commissions_per_user = result.data.result.map((c: any) => {
          c.amount = 0
          if (this.target_expense_model) {
            if (total <= this.target_expense_model.amount) {
              c.amount = c.payable_commission
            } else {
              const share = (c.payable_commission - c.paid_commission) / total
              c.amount = Math.floor(this.target_expense_model.amount * share * 100) / 100
            }
          }

          return c
        })
        this.busy = false
      })
      .catch(() => {
        this.modal_step = 1
        this.invalid_expense = true
        this.busy = false
      })
  }

  public applyPayment() {
    if (!this.target_expense_model) {
      WebMessage.error('Please select an expense')
      return
    }
    const remaining = this.target_expense_model.amount - this.total_commissions.applied

    if (Math.abs(remaining) > 0.05) {
      let message = "You haven't applied the full amount of the expense. Are you sure you want to continue?"

      if (remaining < 0) {
        message = 'You have applied more than the expense amount. Are you sure you want to continue?'
      }

      WebMessage.doubleConfirm(
        message,
        'Expense Discrepancy',
        "I've reviewed the values and it is correct",
        { okTitle: 'Save' },
      ).then(result => {
        if (result) {
          this.submitPayment()
        }
      })

      return
    }

    this.submitPayment()
  }

  public submitPayment() {
    if (!this.target_expense) {
      WebMessage.error('Please select an expense')
      return
    }
    this.$bvModal.hide('modal-add-payment')
    Commission.applyPayment(
      this.filter_type,
      `${this.target_start}-01`,
      `${this.target_end}-01`,
      this.target_expense,
      this.commissions_per_user,
    ).then(() => {
      WebMessage.success('Updating Payments, we will let you know once the update is complete.')
    })
  }
}
