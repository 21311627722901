export default [
  {
    key: 'id',
    label: 'ID',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
  },
  {
    key: 'user_id',
    label: 'User',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },

  {
    key: 'invoice_id',
    label: 'Invoice',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'invoice_paid_date',
    label: 'Invoice Paid Date',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'date',
  },
  {
    key: 'media_plan_ids',
    label: 'Media Plan',
    sortable: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
  },
  {
    key: 'invoice_net_amount',
    label: 'Invoice Net',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'currency',
  },
  {
    key: 'invoice_paid_amount',
    label: 'Invoice Paid Amount',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'currency',
  },
  {
    key: 'costs',
    label: 'Costs',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'currency',
  },
  {
    key: 'base_commission',
    label: 'Eligible Commission',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'currency',
  },
  {
    key: 'final_commission',
    label: 'Confirmed Commission',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'currency',
  },
  {
    key: 'payable_commission',
    label: 'Payable Commission',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'currency',
  },
  {
    key: 'paid_commission',
    label: 'Paid Commission',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'currency',
  },
  {
    key: 'due_commission',
    label: 'Due Commission',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'currency',
  },
  {
    key: 'quarter',
    label: 'Quarter',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'period',
    label: 'Period',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  // {
  //   key: 'action',
  //   label: 'Actions',
  //   sortable: false,
  //   class: 'text-center align-middle text-capitalize',
  //   show: true,
  //   filter: false,
  // },
]
