
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class CommissionStatusBadge extends Vue {
  @Prop()
  public status!: string

  public status_settings: any = {
    // pending, paid, threshold_not_met, cap_reached
    pending: {
      color: 'warning',
      text: 'Pending',
    },
    paid: {
      color: 'success',
      text: 'Paid',
    },
    threshold_not_met: {
      color: 'danger',
      text: 'Threshold Not Met',
    },
    cap_reached: {
      color: 'info',
      text: 'Cap Reached',
    },
    invalidated: {
      color: 'danger',
      text: 'Invalidated',
    },
    overpaid: {
      color: 'danger',
      text: 'Overpaid',
    },
  }
}
